<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="pl-5 pr-5">
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <!-- factory name -->
            <v-row>
              <v-col
                class="align-center d-flex"
                cols="2"
              >
                <div>
                  <h3>{{ $t('messages.factoryName') }}</h3>
                </div>
              </v-col>
              <v-col cols="10">
                <v-row>
                  <v-col>
                    <div
                      v-if="userType === 1 || userType === 2"
                      class="align-self-center mr-9"
                    >
                      <v-autocomplete
                        v-model="selectedCustomer"
                        :items="availableCustomers"
                        :no-data-text="$t('messages.noJobsSelect')"
                        :item-text="getNameCustomer"
                        item-value="id"
                        :readonly="!!shiftPeriodId"
                      />
                    </div>
                    <div v-else>
                      <h3>{{ profile.name ? profile.name : '' }}</h3>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <!-- factory name -->
            <v-row>
              <v-col
                class="align-center d-flex"
                cols="2"
              >
                <div>
                  <h3>{{ $t('messages.option28h') }}</h3>
                </div>
              </v-col>
              <v-col cols="10">
                <v-row>
                  <v-col>
                    <div
                      v-if="userType === 1 || userType === 2"
                      class="align-self-center mr-9"
                    >
                      <v-select
                        v-model="assignType"
                        :readonly="!!shiftPeriodId"
                        :items="option28hs"
                        :no-data-text="$t('messages.noJobsSelect')"
                        item-text="name"
                        item-value="value"
                      />
                    </div>
                    <div v-else>
                      <h3>{{ parseInt(assignType) === 1 ? '日曜日から土曜日まで ' : '7日間連続' }}</h3>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- date picker -->
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col
                class="align-center d-flex mb-6"
                cols="2"
              >
                <div>
                  <h3>{{ $t('messages.date_range') }}</h3>
                </div>
              </v-col>
              <!-- Single Date option selected -->
              <v-col cols="10">
                <div class="align-self-center mr-9">
                  <v-menu
                    ref="datesMenu"
                    v-model="datesMenu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="dates"
                        multiple
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="dates"
                      multiple
                      no-title
                      scrollable
                      :min="nowDay"
                      :readonly="userType === 3"
                      locale="ja"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="saveDate(dates)"
                      >
                        {{ $t('messages.ok') }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="datesMenu = false"
                      >
                        {{ $t('messages.cancel') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
                <p>※一日または数日を選択できる</p>
              </v-col>
              <!-- Range Date option selected -->
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" />
          <v-col cols="5">
            <div
              v-if="!editName"
              @click="openEditName"
            >
              <h2 class="nameFile">
                {{ nameFile }}
              </h2>
            </div>
            <div v-else>
              <validation-observer>
                <v-form
                  ref="form"
                  lazy-validation
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="nameFile"
                    rules="required"
                  >
                    <v-text-field
                      v-model="nameFile"
                      :error-messages="errors[0]"
                      @keydown.enter="editName = !nameFile"
                      @blur="editName = !nameFile"
                    />
                  </validation-provider>
                </v-form>
              </validation-observer>
            </div>
          </v-col>
          <v-col cols="3" />
        </v-row>

        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>

        <v-row
          v-if="userType !== 3"
        >
          <v-col
            class="align-center d-flex"
            cols="3"
          >
            <v-row>
              <v-col cols="5">
                <div class="align-self-center mt-5">
                  <h3>日付選択</h3>
                </div>
              </v-col>
              <v-col cols="7">
                <div class="align-self-center">
                  <v-select
                    v-model="dateSelected"
                    :items="timestampList"
                    :no-data-text="$t('table.messages.no_data')"
                    item-text="text"
                    item-value="value"
                    single-line
                    outlined
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="align-center d-flex"
            cols="3"
          >
            <v-row>
              <v-col cols="7">
                <div class="align-self-center mt-5">
                  <h3>{{ $t("table.headers.amountAssigned") }}</h3>
                </div>
              </v-col>
              <v-col cols="5">
                <div class="align-self-center">
                  <v-text-field
                    v-model="items.length"
                    readonly
                    outlined
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-btn
              :disabled="!shiftPeriodId"
              x-large
              block
              color="accent"
              @click="redirectShiftAlloment"
            >
              {{ $t('messages.parttimeJobAllocation') }}
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              :disabled="!shiftPeriodId"
              x-large
              block
              color="accent"
              @click="submitShift"
            >
              {{ $t('messages.submit') }}
            </v-btn>
          </v-col>
          <v-col
            v-if="items.length > 0"
            cols="2"
          >
            <json-excel
              class="v-btn v-btn--block v-btn--is-elevated v-btn--has-bg theme--light v-size--x-large accent btn-download"
              :fetch="exportShift"
              :name="getNameFileExport()"
            >
              {{ $t('messages.exportCsv') }}
            </json-excel>
          </v-col>
        </v-row>
        <v-row
          v-else
        >
          <v-col />
          <v-col
            offset="3"
            cols="2"
          />
          <v-col cols="2" />
          <v-col cols="2">
            <v-btn
              v-if="userType !== 3"
              x-large
              block
              color="accent"
              @click="createTableShift"
            >
              タイムシート作成
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="items.length > 0">
          <v-col>
            <v-data-table
              class="elevation-1"
              :headers="headers"
              :options.sync="options"
              :items="items"
              :loading-text="$t('table.messages.loading')"
              :loading="loading"
              :items-per-page="10"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 15, 100],
                showFirstLastPage: false,
                'items-per-page-text': $t('table.messages.items_per_page'),
              }"
              :no-data-text="$t('table.messages.no_data')"
            >
              <template
                v-slot:footer.page-text
                class="mr-0"
              >
                {{ $t('table.messages.page') }}
                {{ options.page }}
              </template>

              <template v-slot:item="{ item, index }">
                <tr @click="handleClick(item)">
                  <td>{{ getIndex(index) }}</td>
                  <td><span class="font-weight-bold">{{ getValueObject(item, 'parttimeEmployeeManageId') }}</span></td>
                  <td><span class="font-weight-bold textName">{{ getValueObject(item, 'parttimeEmployeeName') }}</span></td>
                  <td><span class="font-weight-bold textName">{{ getValueObject(item, 'parttimeEmployeeFurigana') }}</span></td>
                  <td><span class="font-weight-bold textName">{{ getValueObject(item, 'parttimeEmployeeLanguage') }}</span></td>
                  <td><span class="font-weight-bold textName">{{ getValueObject(item, 'parttimeEmployeeSex') ? $t('messages.male') : $t('messages.female') }}</span></td>
                  <td><span class="font-weight-bold textName">{{ getValueObject(item, 'parttimeEmployeeVisa') }}</span></td>
                  <td />
                  <td>
                    <div class="textJob font-weight-bold">
                      <p>{{ getValueObject(item, 'jobName') }}</p>
                      <p>{{ getShiftTime(item) }}</p>
                    </div>
                  </td>
                  <template v-if="dateSelected === $t('messages.all')">
                    <td
                      v-for="(date, indexDate) in dates"
                      :key="indexDate"
                    >
                      {{ getShiftforDate(date, item.values) }}
                    </td>
                    <td />
                  </template>
                  <template v-else>
                    <td>
                      {{ getShiftforDate(dateSelected, item.values) }}
                    </td>
                    <td />
                  </template>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="3">
            <v-dialog
              v-show="showPopupEdit === true"
              v-model="showPopupEdit"
              max-width="800px"
              style="font-size:15px"
            >
              <v-card>
                <v-card-text>
                  <v-row class="text-center pt-5">
                    <v-col cols="3">
                      <span class="text-label text-center">
                        {{
                          $t("table.headers.controlNumber")
                        }}
                      </span>
                    </v-col>
                    <v-col cols="4">
                      <span
                        id="id"
                        class="black--text text-center"
                      >
                        <strong>{{ editedItem.parttimeEmployeeManageId }}</strong>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="text-center">
                    <v-col cols="3">
                      <label class="text-label text-center">
                        {{
                          $t("table.headers.name")
                        }}
                      </label>
                    </v-col>
                    <v-col cols="4">
                      <span class="black--text">
                        <strong>{{ editedItem.parttimeEmployeeFurigana }}</strong>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="text-center">
                    <v-col cols="3">
                      <label class="text-label">
                        {{
                          $t("table.headers.job")
                        }}
                      </label>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        v-model="selectedJob"
                        outlined
                        :items="itemsJobs"
                        item-text="name"
                        item-value="id"
                        return-object
                        single-line
                      />
                    </v-col>
                  </v-row>
                  <v-row class="text-center">
                    <v-col cols="3">
                      <label class="text-label">
                        {{
                          $t("table.headers.shift")
                        }}
                      </label>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        v-model="selectedShift"
                        outlined
                        :items="itemsShift"
                        :item-text="item => item.shiftStartAt + ' ~ ' + item.shiftEndAt"
                        item-value="id"
                        return-object
                        single-line
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text>
                  <div class="pr-7 pl-7 pb-7 mr-10 ml-10 card-text-contents">
                    <v-row class="d-flex align-center">
                      <v-col
                        cols="5"
                        class="text-center text-h4 font-weight-medium"
                      >
                        <strong>{{ $t("messages.date") }}</strong>
                      </v-col>
                      <v-col
                        cols="5"
                        class="text-center text-h4 font-weight-medium"
                      >
                        <strong>{{ $t("messages.scheduledTime") }}</strong>
                      </v-col>
                    </v-row>
                    <v-row
                      v-for="(input, k) in editedItem.values"
                      :key="k"
                      class="d-flex align-center"
                    >
                      <v-col
                        cols="5"
                        class="text-center text-h5"
                      >
                        {{ input.workdate }}
                      </v-col>
                      <v-col
                        cols="2"
                        class="text-center"
                      >
                        <v-text-field
                          v-model="input.plannedStartAt1"
                          outlined
                          :readonly="userType === 3"
                          :hide-details="true"
                        />
                      </v-col>~
                      <v-col
                        cols="2"
                        class="text-center"
                      >
                        <v-text-field
                          v-model="input.plannedEndAt1"
                          outlined
                          :readonly="userType === 3"
                          :hide-details="true"
                        />
                      </v-col>
                      <v-col cols="1">
                        <div class="d-flex align-center justify-space-around">
                          <v-icon
                            v-if="(userType === 1 || userType === 2) && !input.isPresent"
                            v-show="k || ( !k && editedItem.values.length > 1)"
                            class="fas fa-minus-circle"
                            size="30px"
                            @click="removeDocumentsRequired(input, k)"
                          >
                            mdi-close-circle
                          </v-icon>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-row class="d-flex justify-center mb-3">
                    <v-col
                      v-if="userType === 1 || userType === 2"
                      cols="2"
                    >
                      <v-btn
                        block
                        color="primary"
                        type="submit"
                        @click="checkEdit"
                      >
                        <span class="text-h5 font-weight-bold">{{ $t("button.save") }}</span>
                      </v-btn>
                    </v-col>
                    <v-col
                      v-if="(userType === 1 || userType === 2) && isPresent"
                      cols="2"
                    >
                      <v-btn
                        block
                        color="red"
                        type="submit"
                        @click="deleteItem"
                      >
                        <span class="text-h5 font-weight-bold">{{ $t("button.delete") }}</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        block
                        color="grey"
                        @click="closeshowPopupEdit"
                      >
                        <span class="text-h5 font-weight-bold white--text">
                          {{
                            $t("messages.close")
                          }}
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row
      class="d-flex justify-end mt-3"
    >
      <v-col
        v-if="shiftPeriodId && (userType === 1 || userType === 2)"
        cols="2"
        class="d-flex justify-space-between"
      >
        <v-btn
          large
          block
          color="accent"
          @click="openDeleteDialog"
        >
          <span class="text-h5 pr-3 pl-3">{{ $t('messages.delete') }}</span>
        </v-btn>
      </v-col>
      <v-col
        v-if="shiftPeriodId && (userType === 1 || userType === 2)"
        cols="2"
        class="d-flex justify-space-between"
      >
        <v-btn
          large
          block
          color="accent"
          @click="onSubmitUpdateShift"
        >
          <span class="text-h5 pr-3 pl-3">{{ $t('messages.save') }}</span>
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn
          large
          block
          color="info"
          @click="cancel"
        >
          <span class="text-h5 pr-3 pl-3">{{ $t('messages.close') }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogCreateShift"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 justify-center lighten-2">
          以下の名前としてシフトを保存しますか？
        </v-card-title>
        <validation-observer v-slot="{ handleSubmit }">
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="handleSubmit(onSubmitCreateShift)"
          >
            <v-card-text>
              <validation-provider
                v-slot="{ errors }"
                name="nameFile"
                rules="required"
              >
                <v-text-field
                  v-model="nameFile"
                  name="nameFile"
                  :error-messages="errors[0]"
                />
              </validation-provider>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn
                large
                color="accent"
                type="submit"
              >
                {{ $t('messages.save') }}
              </v-btn>
              <v-btn
                large
                @click="dialogCreateShift = false"
              >
                {{ $t('messages.cancel') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="font-weight-bold text-h4">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="1">
              <v-icon
                large
                color="blue darken-2"
              >
                mdi-delete-outline
              </v-icon>
            </v-col>
            <v-col cols="8">
              この操作は元に戻りません。
              本当に削除してよろしいですか？
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-spacer />

          <v-btn
            class="text-h5"
            dark
            color="#E43E08"
            @click="deleteItemConfirm"
          >
            {{ $t('messages.delete') }}
          </v-btn>
          <v-btn
            class="text-h5"
            @click="close"
          >
            {{ $t('messages.cancel') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCreateTable"
      width="500"
    >
      <v-card>
        <validation-observer v-slot="{ handleSubmit }">
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="handleSubmit(submitCreatTable)"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <h3>タイムシートの日</h3>
                  <validation-provider
                    v-slot="{ errors }"
                    name="nameFile"
                    rules="required"
                  >
                    <v-select
                      v-model="workdateSelect"
                      :items="dates"
                      outlined
                      :error-messages="errors[0]"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3>タイムシートの名前</h3>
                  <validation-provider
                    v-slot="{ errors }"
                    name="nameFileCustomer"
                    rules="required"
                  >
                    <v-text-field
                      v-model="nameFileCustomer"
                      name="nameFileCustomer"
                      outlined
                      :error-messages="errors[0]"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn
                large
                color="accent"
                type="submit"
              >
                {{ $t('messages.save') }}
              </v-btn>
              <v-btn
                large
                @click="dialogCreateTable = false"
              >
                {{ $t('messages.cancel') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import _ from 'lodash'
  import { get } from 'vuex-pathify'
  import { required } from 'vee-validate/dist/rules'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import JsonExcel from 'vue-json-excel'
  import { api } from '@/config/index'
  import axios from 'axios'

  export default {
    name: 'ShiftInformationDisplay',
    components: {
      ValidationProvider,
      ValidationObserver,
      JsonExcel,
    },
    data () {
      return {
        workdateSelect: '',
        dialogCreateTable: false,
        dialogDelete: false,
        dialogCreateShift: false,
        editName: false,
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        availableCustomers: [],
        selectedCustomer: '',
        dates: [],
        dateSelected: this.$t('messages.all'),
        timestampList: [],
        datesMenu: false,
        items: [],
        search: '',
        headers: [],
        options: {},
        loading: false,
        check: true,
        nameFile: '',
        nowDay: moment(new Date()).format('YYYY-MM-DD'),
        shiftPeriodId: '',
        showPopupEdit: false,
        editedItem: {},
        attendenceRecord: {},
        userType: '',
        nameFileCustomer: '',
        option28hs: [
          {
            value: 1,
            name: '日曜日から土曜日まで ',
          },
        ],
        isPresent: true,
        assignType: 1,
        json_fields: {
          No: 'index',
          管理番号: 'employeeNumber',
          氏名: 'fullName',
          国籍: 'country',
          工場: 'customer',
          ジョブ: 'job',
          シフト: 'shift',
          休憩: 'breaktime',
          実際の推定労働時間: 'planned',
        },
        json_data: [],
        itemsJobs: [],
        selectedJob: {},
        itemsShift: [],
        selectedShift: {},
        customerName: '',
      }
    },
    computed: {
      ...get('shift', [
        'shiftList',
        'shiftPeriod',
        'listAttendenceRecord',
        'selectedShiftPeriod',
        'message',
        'status',
        'error',
        'attendenceRecordsLength',
      ]),
      ...get('customer', ['list', 'listJobs']),
      ...get('user', ['profile']),
    },
    watch: {
      error (value) {
        this.showSnackBar(value)
      },
      list (value) {
        this.availableCustomers = value.data.customers.filter(el => !el.isFixed)
      },
      selectedCustomer (value) {
        this.openCreateShift()
        const customers = this.list.data.customers
        customers.forEach(customer => {
          if (customer.id === value) {
            this.customerName = customer.name
          }
        })
      },
      dateSelected (value) {
        if (value === this.$t('messages.all')) {
          this.$store.dispatch('shift/getDetailPeriodShift', {
            id: this.shiftPeriodId,
          })
        } else {
          this.$store.dispatch('shift/getDetailPeriodShift', {
            id: this.shiftPeriodId,
            workdate: value,
          })
        }
      },
      shiftPeriod (value) {
        const formatDateSelected = moment(this.dateSelected).locale('ja')
        this.shiftPeriodId = value.id
        this.selectedCustomer = value.customerId
        this.assignType = value.assignType
        this.nameFile = value.name
        this.items = this.converItem(value.attendenceRecords)
        this.dates = JSON.parse(value.timestamps)
        this.timestampList = [this.$t('messages.all'), ...this.dates]
        this.workdateSelect = _.get(this.dates, '[0]', '')
        this.dates.sort(function (a, b) {
          return new Date(a) - new Date(b)
        })

        this.getHeader()
        this.dateSelected === this.$t('messages.all') ? this.dates.forEach(item => {
          const day = moment(item).locale('ja')
          this.headers.push({
            text: day.format('DD') + ' - ' + day.format('ddd'),
            align: 'center',
            value: item,
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '8rem',
          })
        }) : this.headers.push({
          text: formatDateSelected.format('DD') + ' - ' + formatDateSelected.format('ddd'),
          align: 'center',
          value: formatDateSelected,
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '8rem',
        })

        this.selectedShiftPeriod.forEach((item) => {
          if (this.dateSelected === this.$t('messages.all')) {
            this.items.push(item)
          } else {
            if (item.values.find((i) => i.workdate === this.dateSelected)) {
              this.items.push(item)
            }
          }
        })
        this.headers.push({
          text: this.$t('table.headers.remarks'),
          align: 'center',
          value: 'note',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '8rem',
        })
        this.$store.dispatch('customer/getCustomerJobs', value.customerId)
        this.$store.dispatch('shift/getListAssigned', this.items)
      },
      listAttendenceRecord (value) {
        if (value.success) {
          this.editedItem.values.map(item => {
            this.editedItem.shiftEndAt = item.plannedEndAt1
            this.editedItem.shiftStartAt = item.plannedStartAt1
            item.plannedStartAt = item.plannedStartAt1
            item.plannedEndAt = item.plannedEndAt1
          })
          this.items.find(ele => {
            if (ele.shiftId === this.editedItem.shiftId && ele.jobId === this.editedItem.jobId && ele.parttimeEmployeeId === this.editedItem.parttimeEmployeeId) {
              ele.values = this.editedItem.values
              return true
            }
            return false
          })
        }
      },
      editedItem (value) {
        this.isPresent = this.checkIsPresent(value.values)
        this.selectedJob = {
          id: parseInt(value.jobId),
          name: value.jobName,
        }
        this.selectedShift = {
          id: parseInt(value.shiftId),
          shiftStartAt: value.shiftStartAt,
          shiftEndAt: value.shiftEndAt,
          planBreakTime: value.planBreakTime,
        }
      },
      message (value) {
        if (value === 'successUpdate') {
          if (this.shiftPeriodId) {
            this.initialize(this.shiftPeriodId)
          }
          this.showSnackBar('messages.updateSuccess')
        }

        if (value === 'successCreate') {
          this.showSnackBar('messages.updateSuccess')
        }

        if (value === 'successDelete') {
          this.$router.push('/shift-information-display')
        }
      },
      workdateSelect (value) {
        const textDate = moment(value).format('YYYY年MM月DD日')
        this.nameFileCustomer = this.profile.name + '_' + textDate + '_' + 'タイムシート'
      },
      listJobs (value) {
        value.map(item => {
          this.itemsJobs.push({
            id: item.id,
            name: item.name,
          })
        })
      },
      selectedJob (value) {
        const payload = {
          jobId: value.id,
        }
        this.$store.dispatch('shift/getShiftByJob', payload)
        this.itemsShift = []
        this.editedItem.jobId = value.id
        this.editedItem.jobName = value.name
      },
      shiftList (value) {
        value.map(item => {
          this.itemsShift.push({
            id: item.id,
            shiftEndAt: item.shiftEndAt,
            shiftStartAt: item.shiftStartAt,
            planBreakTime: item.planBreakTime,
          })
        })
      },
      selectedShift (value) {
        this.editedItem.shiftId = value.id
        this.editedItem.shiftEndAt = value.shiftEndAt
        this.editedItem.shiftStartAt = value.shiftStartAt
        this.editedItem.planBreakTime = value.planBreakTime
      },
    },
    created () {
      this.$store.dispatch('user/getProfile')
      this.userType = JSON.parse(
        JSON.parse(localStorage.getItem('user')).data,
      ).type
      if (this.userType === 1 || this.userType === 2) {
        this.$store.dispatch('customer/getCustomers')
      }
    },
    mounted () {
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })
      if (this.$route.params.id) {
        this.shiftPeriodId = this.$route.params.id

        this.initialize(this.shiftPeriodId)
      }
      // check succes message
      if (this.$route.query) {
        if (this.$route.query.success) this.showSnackBar('messages.success')
      }
      // init edit if
      if (this.$route.query.customerId) {
        this.selectedCustomer = parseInt(this.$route.query.customerId)
      }

      this.headers = []

      this.mainHeaders = [
        {
          text: this.$t('table.headers.index'),
          align: 'center',
          value: 'index',
          class: 'font-weight-bold',
          sortable: false,
          width: '4rem',
        },
        {
          text: this.$t('table.headers.manageId'),
          align: 'start',
          value: 'manageId',
          class: 'font-weight-bold',
          sortable: false,
          width: '8rem',
        },
        {
          text: this.$t('table.headers.name'),
          align: 'start',
          value: 'name',
          class: 'font-weight-bold',
          sortable: false,
          width: '12rem',
        },
        {
          text: this.$t('table.headers.furiganaName'),
          align: 'start',
          value: 'furiganaName',
          class: 'font-weight-bold',
          sortable: false,
          width: '12rem',
        },
        {
          text: this.$t('table.headers.language'),
          align: 'center',
          value: 'language',
          class: 'font-weight-bold',
          sortable: false,
          width: '4rem',
        },
        {
          text: this.$t('table.headers.sex'),
          align: 'center',
          value: 'sex',
          class: 'font-weight-bold',
          sortable: false,
          width: '4rem',
        },
        {
          text: this.$t('table.headers.qualificationOfExistence'),
          align: 'center',
          value: 'qualificationOfExistence',
          class: 'font-weight-bold',
          sortable: false,
          width: '4rem',
        },

        {
          text: this.$t('table.headers.transfer'),
          align: 'center',
          value: 'transfer',
          class: 'font-weight-bold',
          sortable: false,
          width: '8rem',
        },
        {
          text: this.$t('table.headers.job'),
          align: 'center',
          value: 'job',
          class: 'font-weight-bold',
          sortable: false,
          width: '8rem',
        },
      ]

      this.getHeader()
    },
    methods: {
      converItem (items) {
        items.forEach(item => {
          item.values.forEach(value => {
            const startAt = moment.utc(value.plannedStartAt)
            const endAt = moment.utc(value.plannedEndAt)
            const dateStart = startAt.format('YYYY-MM-DD')
            const dateEnd = endAt.format('YYYY-MM-DD')
            let startTime = startAt.format('HH:mm')
            let endTime = endAt.format('HH:mm')

            if (moment(dateStart).diff(moment(value.workdate), 'days') > 0) {
              startTime = this.convertTime(parseFloat(this.converNumber(startTime)) + 24)
            }

            if (moment(dateEnd).diff(moment(value.workdate), 'days') > 0) {
              endTime = this.convertTime(parseFloat(this.converNumber(endTime)) + 24)
            }

            value.plannedStartAt = startTime
            value.plannedEndAt = endTime
          })
        })

        return items
      },
      checkIsPresent (items) {
        items.forEach(item => {
          if (item.isPresent) {
            return false
          }
        })
        return true
      },
      async exportShift () {
        const jsonData = []
        let header
        if (this.dateSelected === this.$t('messages.all')) {
          header = this.dates.reduce((arr, value) => ({ ...arr, [moment(value).format('出勤日YYYY年MM月DD日')]: '' }), {})
        } else {
          header = {
            [moment(this.dateSelected).format('出勤日YYYY年MM月DD日')]: '',
          }
        }
        this.items.map((item, index) => {
          const sex = item.parttimeEmployeeSex ? this.$t('messages.male') : this.$t('messages.female')
          let data = {
            No: index + 1,
            管理番号: item.parttimeEmployeeManageId + '\n' + sex,
            氏名: item.parttimeEmployeeName + '\n' + item.parttimeEmployeeFurigana,
            国籍: item.parttimeEmployeeLanguage,
            工場: this.customerName,
            ジョブ: item.jobName,
            シフト: item.shiftStartAt + ' ~ ' + item.shiftEndAt,
            休憩: item.planBreakTime * 60,
          }
          const workdate = this.convertArrayToObject(item.values, 'workdate')
          data = { ...data, ...header, ...workdate }
          jsonData.push(data)
        })
        return jsonData
      },
      getHeader () {
        this.headers = _.clone(this.mainHeaders)
      },
      getShiftforDate (date, arrayDate) {
        const dateShift = arrayDate.find(item => item.workdate === date)
        if (dateShift) {
          const startAt = dateShift.plannedStartAt
          const endAt = dateShift.isLast
            ? 'ラスト'
            : dateShift.plannedEndAt
          return startAt + ' ~ ' + endAt
        }
        return ''
      },
      saveDate (value) {
        this.$refs.datesMenu.save(value)

        if (this.shiftPeriodId) {
          this.onSubmitUpdateShift()
        } else {
          this.openCreateShift()
        }
      },
      redirectShiftAlloment () {
        if (!this.shiftPeriodId) {
          this.openCreateShift()
          return
        }

        if (!this.dates) return
        this.$router.push({
          path: '/shift-information-display/' + this.shiftPeriodId + '/allotment',
          query: { customerId: this.selectedCustomer, dates: this.dates, assignType: this.assignType, total: this.items.length || 0, totalDb: this.attendenceRecordsLength || 0 },
        })
      },
      getNameCustomer (customer) {
        return _.get(customer, 'code', '')
          ? _.get(customer, 'code', '') + ' - ' + _.get(customer, 'name', '')
          : _.get(customer, 'name', '')
      },
      openCreateShift () {
        if (
          this.selectedCustomer &&
          this.dates.length > 0 &&
          !this.shiftPeriodId
        ) {
          const customer = this.availableCustomers.find(
            item => this.selectedCustomer === item.id,
          )
          const date = this.dates[0]

          const textDate = moment(date).format('YYYY年MM月DD日')

          this.nameFile = customer.name + '_' + textDate + '_' + '出勤簿'
          this.dialogCreateShift = true
        }
      },
      getValueObject (item, path, d = '') {
        return _.get(item, path, d)
      },
      getShiftTime (item) {
        return this.getValueObject(item, 'shiftStartAt') + '~' + this.getValueObject(item, 'shiftEndAt') +
          ' (' + Math.round(this.getValueObject(item, 'planBreakTime') * 60) + ')'
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      onSubmitCreateShift: _.debounce(async function () {
        if (!this.nameFile) return
        if (!this.dates) return

        const payload = {
          customerId: this.selectedCustomer,
          name: this.nameFile,
          timestamps: this.dates,
          assignType: this.assignType,
          assignees: [],
          notes: [],
        }

        await this.$store.dispatch('shift/createPeriodShift', payload)

        await this.redirectShiftAlloment()
        this.close()
      }, 500),
      getNameFileExport () {
        if (this.dateSelected === this.$t('messages.all')) {
          return this.customerName + '_' + this.$t('messages.all') + '_' + '出勤簿'
        } else {
          const textDate = moment(this.dateSelected).format('YYYY年MM月DD日')
          return this.customerName + '_' + textDate + '_' + '出勤簿'
        }
      },
      onSubmitUpdateShift: _.debounce(async function () {
        if (!this.nameFile) return
        if (!this.shiftPeriodId) return
        if (!this.dates) return

        const payload = {
          shiftId: this.shiftPeriodId,
          customerId: this.selectedCustomer,
          name: this.nameFile,
          assignType: this.assignType,
          timestamps: this.dates,
          assignees: this.getAssignees(),
          notes: [],
        }

        this.$store.dispatch('shift/updatePeriodShift', payload)
      }, 500),
      openDeleteDialog () {
        if (!this.shiftPeriodId) {
          return
        }
        this.dialogDelete = true
      },
      getAssignees () {
        const assign = []
        this.items.map(item => {
          item.values.map(value => {
            let timeStart = value.plannedStartAt
            let timeEnd = value.plannedEndAt
            let workdateStart = value.workdate
            let workdateEnd = value.workdate

            if (this.converNumber(value.plannedStartAt) > 24) {
              timeStart = this.convertTime(this.converNumber(value.plannedStartAt) - 24)
              workdateStart = moment(value.workdate).add('1', 'day').format('YYYY-MM-DD')
            }

            if (this.converNumber(value.plannedEndAt) > 24) {
              timeEnd = this.convertTime(this.converNumber(value.plannedEndAt) - 24)
              workdateEnd = moment(value.workdate).add('1', 'day').format('YYYY-MM-DD')
            }

            const startAt = workdateStart + ' ' + timeStart
            const endAt = workdateEnd + ' ' + timeEnd
            const data = {
              parttimeEmployeeId: item.parttimeEmployeeId,
              shiftId: item.shiftId,
              workdate: value.workdate,
              plannedStartAt: startAt,
              plannedEndAt: endAt,
              isLast: value.isLast,
            }
            if (value.id) {
              data.id = value.id
            }
            assign.push(data)
          })
        })
        return assign
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },

      async deleteItemConfirm () {
        await this.$store.dispatch('shift/deletePeriodShift', {
          shiftId: this.shiftPeriodId,
        })

        this.close()
      },
      close () {
        this.dialogCreateShift = false
        this.dialogDelete = false
      },
      async initialize (id) {
        await this.$store.dispatch('shift/getDetailPeriodShift', {
          id: id,
        })
      },
      cancel () {
        this.nameFile = this.shiftPeriod.name
        this.items = this.shiftPeriod.attendenceRecords
        this.$router.push('/shift-list')
      },
      closeCreate () {
        this.nameFile = ''
        this.dialogCreateShift = false
      },
      checkDate () {
        let check = true
        this.dates.forEach(item => {
          if (moment(new Date()).diff(moment(item), 'day') > 0) {
            check = false
          }
        })
        return check
      },
      // Popup Edit
      handleClick (row) {
        this.showPopupEdit = true
        this.editedItem = { ...row }

        this.editedItem.values.map(item => {
          item.plannedEndAt1 = item.plannedEndAt
          item.plannedStartAt1 = item.plannedStartAt
        })
      },
      submitShift: _.debounce(
        function () {
          const payload = {
            id: this.shiftPeriodId,
          }
          this.$store.dispatch('shift/submitPeriodShift', payload)
        }, 500),
      convertDate (item) {
        return moment.utc(item).format('HH:mm')
      },
      removeDocumentsRequired (item, index) {
        const itemRemove = this.editedItem.values.splice(index, 1)
      },

      converNumber (str) {
        const timeStart = parseInt(str.slice(0, 2))
        const timeEnd = (parseInt(str.slice(3, 5)) / 60)
        return parseFloat(timeStart + timeEnd).toFixed(2)
      },
      convertTime (float) {
        let hour = parseInt(float)
        let minute = String(Math.round((float - parseInt(float)) * 60))

        if (hour.length === 1) {
          hour = '0' + hour
        }
        if (minute.length === 1) {
          minute = '0' + minute
        }

        return hour + ':' + minute
      },
      checkEdit () {
        const datetimes = []

        this.items.forEach(item => {
          if (item.parttimeEmployeeManageId === this.editedItem.parttimeEmployeeManageId) {
            item.values.map(value => {
              let timeStart = value.plannedStartAt1 ? value.plannedStartAt1 : value.plannedStartAt
              let timeEnd = value.plannedEndAt1 ? value.plannedEndAt1 : value.plannedEndAt
              let workdateStart = value.workdate
              let workdateEnd = value.workdate

              if (parseFloat(this.converNumber(timeStart)) > 24) {
                timeStart = this.convertTime(this.converNumber(timeStart) - 24)
                workdateStart = moment(value.workdate).add('1', 'day').format('YYYY-MM-DD')
              }

              if (parseFloat(this.converNumber(timeEnd)) > 24) {
                timeEnd = this.convertTime(this.converNumber(timeEnd) - 24)
                workdateEnd = moment(value.workdate).add('1', 'day').format('YYYY-MM-DD')
              }
              this.editedItem.values.map(edit => {
                if (value.id === edit.id) {
                  item.jobId = this.editedItem.jobId.toString()
                  item.shiftId = this.editedItem.shiftId.toString()
                  item.jobName = this.editedItem.jobName
                  item.shiftStartAt = this.editedItem.shiftStartAt
                  item.shiftEndAt = this.editedItem.shiftEndAt
                }
              })
              datetimes.push({
                attendenceRecordId: value.id,
                date: value.workdate,
                startAt: moment.utc(workdateStart + ' ' + timeStart).format(),
                endAt: moment.utc(workdateEnd + ' ' + timeEnd).format(),
                planBreakTime: item.planBreakTime,
              })
            })
            item.planBreakTime = this.editedItem.planBreakTime
          }
        })

        const payload = {
          parttimeEmployeeId: this.editedItem.parttimeEmployeeId,
          datetimes: datetimes,
        }
        this.$store.dispatch('shift/checkAttendenceRecord', payload).then(() => {
          this.updateWorktime(this.editedItem)
        })
        this.closeshowPopupEdit()
      },
      updateWorktime (item) {
        let worktime = 0

        item.values.forEach(value => {
          worktime = worktime + parseFloat(this.processWorkTime(value.plannedStartAt1, value.plannedEndAt1))
        })

        this.items.forEach(it => {
          if (
            it.parttimeEmployeeManageId === item.parttimeEmployeeManageId &&
            it.jobId === item.jobId &&
            it.shiftId === item.shiftId
          ) {
            it.workingHour = worktime
          }
        })
      },
      processWorkTime (startAt, endAt) {
        let timeStart = startAt
        let timeEnd = endAt
        if (this.converNumber(startAt) > 24) {
          timeStart = this.convertTime(this.converNumber(startAt) - 24)
        }

        if (this.converNumber(endAt) > 24) {
          timeEnd = this.convertTime(this.converNumber(endAt) - 24)
        }

        const startTime = moment('2021-01-01 ' + timeStart)
        const endTime = moment('2021-01-01 ' + timeEnd)

        if (endTime.diff(startTime, 'minute') < 0) {
          endTime.add(24 * 60, 'minute')
        }
        const minute = Math.abs(endTime.diff(startTime, 'minute'))
        return (minute / 60).toFixed(2)
      },
      deleteItem () {
        for (let i = 0; i < this.items.length; i++) {
          if (parseInt(this.items[i].shiftId) === parseInt(this.editedItem.shiftId) && parseInt(this.items[i].parttimeEmployeeId) === parseInt(this.editedItem.parttimeEmployeeId) && parseInt(this.items[i].jobId) === parseInt(this.editedItem.jobId)) {
            this.items.splice(i, 1)
            i--
          }
        }
        this.closeshowPopupEdit()
      },
      closeshowPopupEdit () {
        this.showPopupEdit = false
      },
      createTableShift () {
        const textDate = moment(this.workdateSelect).format('YYYY年MM月DD日')

        this.nameFileCustomer = this.profile.name + '_' + textDate + '_' + 'タイムシート'
        this.dialogCreateTable = true
      },
      submitCreatTable () {
        const payload = {
          name: this.nameFileCustomer,
          workdate: this.workdateSelect,
          shiftPeriodId: this.shiftPeriodId,
        }
        this.$router.push({ path: '/detail-time-sheet', query: payload })
      },
      openEditName () {
        if (this.userType === 1 || this.userType === 2) {
          this.editName = true
        }
      },
      convertArrayToObject (array, key) {
        const initialValue = {}
        return array.reduce((obj, item) => {
          return {
            ...obj,
            [moment(item[key]).format('出勤日YYYY年MM月DD日')]: item.plannedStartAt + ' ~ ' + item.plannedEndAt,
          }
        }, initialValue)
      },
    },
  }
</script>
<style lang="css">
table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.nameFile {
  margin-top: 20px;
}

.textJob {
  text-align: center;
  color: #4262ff;
}
.text-label {
  font-size: 20px;
  color: #5ca6d2;
  font-weight: bold;
}
tbody tr td {
  cursor: pointer;
}

.btn-download {
  cursor: pointer;
}
</style>
